
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Modal } from 'ant-design-vue';
import Project from '@/models/Project';
import { ModalWidth } from '@/enums/global/ModalWidth';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import PaymentRecordFormNew from '@/components/views/NewProjectView/Payments/PaymentRecordFormNew.vue';

@Component({
    name: 'PaymentRecordModal',
    components: {PaymentRecordFormNew, Modal},
})
export default class PaymentRecordModal extends Vue {
    @Prop({required: true}) private projectId!: string;
    @Prop({default: false}) private isModalVisible!: boolean;
    @Prop({required: true}) private editingPaymentRecordId!: string | null;
    @Prop({required: true}) private project!: Project;

    private modalWidth = ModalWidth;
    private isLoading = false;

    private get defaultCurrency() {
        if (this.project == null || this.project.projectClient == null || this.project.projectClient.currency == null) {
            const euroCurrency = CurrencyRepository.getBySymbol('€');
            if (euroCurrency == null) {
                return '';
            }

            return euroCurrency.id;
        }

        return this.project.projectClient.currency.id;
    }

    private closePaymentRecordModal() {
        this.$emit('update:isModalVisible', false);
        this.$emit('resetEditingPaymentRecordId');
    }


}
