
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Button, Tooltip } from 'ant-design-vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import PaymentRecordModal from '@/components/views/NewProjectView/Payments/PaymentRecordModal.vue';
import Project from '@/models/Project';

@Component({
    name: 'PaymentRecordHandler',
    components: {PaymentRecordModal, Tooltip, Button},
})
export default class PaymentRecordHandler extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({required: true}) private projectId!: string;
    @Prop({required: true}) private project!: Project;
    @Prop({default: null}) private editingPaymentRecordId!: string | null;

    private isModalVisible = false;

    private openPriceManipulationModal() {
        this.isModalVisible = true;
    }

    @Watch('editingPaymentRecordId')
    private onEditingPaymentRecordIdChange() {
        if (this.editingPaymentRecordId != null) {
            this.isModalVisible = true;
        }
    }

}
