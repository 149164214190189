import PaymentRecord from '@/models/PaymentRecord';

export default class PaymentRecordRepository {
    public static getAll() {
        return PaymentRecord.all();
    }

    public static getById(id: string) {
        return PaymentRecord.query().whereId(id).withAll().first();
    }
}
